<template>
  <AppLayout2>
    <router-view> </router-view>
  </AppLayout2>
</template>

<script setup>
import AppLayout2 from './layouts/AppLayout2.vue';
import { isLoggedIn, baseLogo, showEazeLogo, baseLogoMobile } from "@/store/generalStore"
import { onBeforeMount } from 'vue'
onBeforeMount(function () {
  try {
    const config = localStorage.getItem('appConfig')
    const appConfig = JSON.parse(config)
    if (typeof appConfig === 'object' && appConfig !== null) {
      if (appConfig.logo) {
        baseLogo.value = appConfig.logo
        const links = document.querySelectorAll("link[rel~='icon']");
        for (let index = 0; index < links.length; index++) {
          links[index].href = appConfig.logo
        }
      }
      if (appConfig.mobileLogo) {
        baseLogoMobile.value = appConfig.mobileLogo
      }
      const colorConfig = appConfig.colors
      if (appConfig.title)
        document.title = appConfig.title
      if (appConfig.showEazeLogo)
        showEazeLogo.value = appConfig.showEazeLogo
      if (typeof appConfig.showEazeLogo === 'undefined')
        showEazeLogo.value = true
      if (colorConfig.primary)
        document.documentElement.style.setProperty('--primary-color-core', colorConfig.primary);
      if (colorConfig.textButton)
        document.documentElement.style.setProperty('--text-btn-color', colorConfig.textButton);
    }
    isLoggedIn.value = true
  } catch (e) {
    console.log(e)
    isLoggedIn.value = true
  }
})
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");

:root {
  --primary-color-core: #3B5CAD;
  --text-btn-color: #ffff;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
