<template>
    <NavBar v-if="isLoggedIn" />
    <BaseContainer>
        <slot/>
    </BaseContainer>
    <FloatingButton class="hidden">
        <ChatBubbleBottomCenterIcon class="w-8"/>
    </FloatingButton>
</template>
<script setup>
import { ChatBubbleBottomCenterIcon } from  '@heroicons/vue/24/outline'
import FloatingButton from '@/components/common/FloatingButton.vue'
import NavBar from '@/components/Navbar/NavBar.vue'
import BaseContainer from '@/components/common/BaseContainer.vue';
// import { isLoggedIn } from '@/store/generalStore';
const isLoggedIn = localStorage.getItem('isLoggedIn')

</script>