// src/middleware/auth.js
import { useRouter } from 'vue-router';
export function requireAuth(to, from, next) {
    const isAuthenticated = !!localStorage.getItem('TokenCode'); // or your own authentication logic
    const isLoggedIn = !!localStorage.getItem('isLoggedIn'); // or your own authentication logic
    const router = useRouter()
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!isAuthenticated || !isLoggedIn) {
        router.push({ name: 'Error', params: { message: btoa(JSON.stringify({ message: "Credential Invalid!!!" })) } })
      } else {
        next();
      }
    } else {
      next();
    }
  }
  