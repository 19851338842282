import { createRouter, createWebHistory } from 'vue-router'
import { requireAuth } from "@/middlewares/auth"

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/access/invalid',
    component: () => import('./views/PortalView.vue'),
    meta: {
      layout: 'PortalLayout',
    },
  },
  {
    path: '/access/:credential',
    name: 'Access',
    component: () => import('./views/AccessView.vue'),
  },
  {
    path: '/payment',
    name: 'HomePayment',
    component: () => import('./views/PortalView.vue'),
    meta: {
      layout: 'PortalLayout',
      requiresAuth: true
    },
  },
  {
    path: '/payment/tracking',
    name: 'Tracking',
    component: () => import('./views/TrackingView.vue'),
    meta: {
      layout: 'PortalLayout',
      requiresAuth: true
    },
  },
  {
    path: '/payment/:client_refnum',
    name: 'Payment',
    component: () => import('./views/PaymentView.vue'),
    meta: {
      layout: 'PortalLayout',
      requiresAuth: true
    },
  },
  {
    path: '/detail-news',
    name: 'DetailNews',
    component: () => import('./views/DetailNews.vue'),
  },
  {
    path: '/receipt/:client_refnum',
    name: 'DownloadReceipt',
    props: route => ({ ...route.params, client_refnum: parseInt(route.params.client_refnum) }),
    component: () => import('./views/ReceiptView.vue'),
    requiresAuth: true
  },

  {
    path: '/tracking/:client_refnum',
    name: 'GenerateTracking',
    props: route => ({ ...route.params, client_refnum: parseInt(route.params.client_refnum) }),
    component: () => import('./views/GenerateTracking.vue'),
    requiresAuth: true
  },
  {
    path: '/generate',
    name: 'Generate',
    props: route => ({
      pay: route.query.pay,
    }),
    component: () => import('./views/GenerateViewVA.vue'),
  },
  {
    path: '/payment-link/:id',
    name: 'PaymentLink',
    component: () => import('./views/GenerateView.vue'),
  },
  {
    path: '/history/:id',
    name: 'History',
    component: () => import('./views/HistoryView.vue'),
  },
  {
    path: '/error/:message',
    name: 'Error',
    component: () => import('./views/ErrorView.vue'),
  }, {
    path: '/:catchAll(.*)',
    props:{
      message : 'Halaman Tidak Ditemukan' 
    },
    name: 'NotFound',
    component: () => import('./views/ErrorView.vue')
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
router.beforeEach((to, from, next) => {
  requireAuth(to, from, next);
})

export default router
